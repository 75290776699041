.container {
  position: fixed;
  top: 0.16rem;
  left: 0.15rem;
  width: 0.35rem;
  background: #fff;
  border-radius: 0.05rem;
  z-index: 202;
  display: flex;
  justify-content: center;
  box-shadow: 0 0.04rem 0.19rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.IconWrap {
  display: flex;
  align-items: center;
  padding: 0.04rem 0;
}
.Icon {
  width: .35rem;
  height: .35rem;
  margin: 0 auto;
}


.flexCenter{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  color: #5B6780;
  padding: 0.04rem 0;
  img {
    width: 0.35rem;
    height: 0.35rem;
  }
  position: relative;
}

.flexCenter::before,.accept::before {
  content: "";
  display: table;
  width: 0.18rem;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.accept {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.04rem 0;
  img{
    width: 0.35rem;
    height: 0.35rem;
  }
  position: relative;
}

 
